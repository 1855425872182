<template>
<div class="config-station-container">
  <div class="station-data-area" ref="table">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column type="seq" width="60" ></vxe-column>
      <vxe-column field="name" :title="showLang('com.tab.title')" width="120" header-align="center"></vxe-column>
      <vxe-column field="interval" :title="showLang('lux.gather.interval')" header-align="center">
        <template #default="{ row }">
          {{getLuxInterval(row)}}
        </template>
      </vxe-column>
      <vxe-column width="220" title="操作" fixed="right">
        <template #header>
          <Button v-if='funCodes(1501)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
          <Button v-if='funCodes(1500)' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <Button v-if='funCodes(1502)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
          <Button v-if='funCodes(1503)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <ModalRuleEdit v-model="showEditModal" :item="editItem" @saved="getList" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalRuleEdit from './ModalRuleEdit'
export default {
  name: 'LuxConfigGroup',
  components:{
    ModalRuleEdit,
  },
  data () {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      list: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getList();
  },
  methods: {
    getList: function(){
      this.$axios.post(`device/lux/QueryRule`, {}).then(res => {
        if(res.code === 0){
          this.$set(this, 'list', res.data)
        }
      });
    },
    getLuxInterval: function(row){
      if(!row.intervals || row.intervals.length == 0)return `${row.interval}秒`;
      let arr = [...row.intervals];
      arr.sort((a, b) => a.val - b.val);
      let str = '';
      for(let item of arr){
        str += `小于${item.val}时，间隔${item.interval}秒；`;
      }
      str += `否则，间隔${row.interval}秒`;
      return str;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('lux.is.del.Control.rules',params.row.name),
        onOk: async () => {
          this.$axios.post(`device/lux/DeleteRule`, {id: params.row.id}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>